import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const LoginView = () =>
    import('@/components/login/login.view')
const AppsView = () =>
    import('@/components/apps/apps.view')
const mainTemplateView = () =>
    import('@/components/mainTemplate/main.view')

const uresView = () =>
    import('@/components/ures/ures.view')
const usuariosView = () =>
    import('@/components/usuarios/usuarios.view')

const LoginTemporal = () => 
    import('@/components/login/loginTemporal') 

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/', redirect: '/login' },
        { path: '/login', name: 'Login', component: LoginView },
        { path:'/login-code', name: 'LoginCode', component: LoginTemporal },
        { path: '/apps', name: 'Apps', component: AppsView },
        {
            path: '/main', name: 'Main', component: mainTemplateView, redirect: '/main/ures/0',
            children: [
                {
                path: 'ures/:numero',
                name: 'URES',
                component: uresView
            },
                {
                path: 'usuarios',
                name: 'Usuarios',
                component: usuariosView
            }
        ]
        },

        {
            //Cualquier ruta que no exista, se redireccionará al main
            path: '*',
            redirect: '/apps',
            // name: 'Not found',
            // component: Page404
        }
    ]
})